import { logicUrl } from "../../../../../helper";
import axios from "axios";
import { useSnackbar } from "notistack";
import ConfirmButton from "../../../../../components/ConfirmButton";

export default function SubscriptionState({ subscription, accessToken }) {
  const { enqueueSnackbar } = useSnackbar();
  const cancelSubscription = async () => {
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/payment/cancelSubscription`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          enqueueSnackbar("Success", { variant: "success" });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <br />
      <br />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Subscription
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A detail list of my plan
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <ConfirmButton
              onClick={cancelSubscription}
              content={"Cancel The Plan"}
              title={"Are you sure you want to cancel the plan"}
              description={
                "You still can use the service before your period end time, you will not be charge in next month but unsubscription will not refund"
              }
            />
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <tbody className="bg-white">
                  <tr className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {"Current Period Start"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {new Date(
                        parseInt(subscription.current_period_start * 1000)
                      )
                        .toLocaleDateString("zh", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                        .replaceAll("/", "-")}
                    </td>
                  </tr>
                  <tr className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {"Status"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {subscription?.status}
                    </td>
                  </tr>
                  <tr className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {"Current Period End"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {new Date(
                        parseInt(subscription.current_period_end * 1000)
                      )
                        .toLocaleDateString("zh", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                        .replaceAll("/", "-")}
                    </td>
                  </tr>
                  <tr className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {"Plan"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {subscription?.plan?.currency}{" "}
                      {subscription?.plan?.amount / 100}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
