export const stage = "prod";

export const logicUrl = `https://7lfd8qwk10.execute-api.ap-southeast-1.amazonaws.com/${stage}`;
export const userPoolClientId = "6hcdpliul4pof2j8qtb1cgrc7u";
export const userPoolId = "ap-southeast-1_6GoIHxys9";
export const region = "ap-southeast-1";

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );
  return bytes.toFixed(dp) + " " + units[u];
};
