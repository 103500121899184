"use client";

import { useEffect, useState } from "react";
import { logicUrl } from "../../../../../helper";
import axios from "axios";
import Skeleton from "../../../../../components/Skeleton";
import DocList from "./DocList";

export default function DisplayDoc({
  trainingState,
  accessToken,
  getTrainingState,
}) {
  const [allDocData, setAllDocData] = useState();
  const [trainingDoc, setTrainingDoc] = useState();
  const [loaded, setLoaded] = useState(false);
  const [startSubmitTrain, setStartSubmitTrain] = useState(false);

  useEffect(() => {
    if (accessToken) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/doc/getAllFile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          setAllDocData(response.data.Items);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [accessToken]);

  useEffect(() => {
    const userTarin = trainingState?.userTrain?.Items;
    if (userTarin && allDocData) {
      const docIdListJson = JSON.parse(userTarin?.[0]?.docIdList.S || "[]");
      setTrainingDoc(
        allDocData
          .filter((doc) => doc?.state?.S === "SUCCESS")
          .map((successDoc) => {
            return {
              ...successDoc,
              select: docIdListJson.includes(successDoc?.fileId.S),
              localTime: new Date(parseInt(successDoc.createTime?.N))
                .toLocaleDateString("zh", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })
                .replaceAll("/", "-"),
            };
          })
      );
      setLoaded(true);
    }
  }, [trainingState, allDocData]);

  const selectDoc = (fileId) => {
    setTrainingDoc(
      trainingDoc.map((doc) => ({
        ...doc,
        select: doc?.fileId?.S === fileId ? !doc.select : doc.select,
      }))
    );
  };

  const sendStartTrain = () => {
    if (accessToken) {
      setStartSubmitTrain(true);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/bot/startTraining`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          fileList: trainingDoc
            .filter((doc) => {
              return doc.select;
            })
            .map((td) => {
              return td?.fileId?.S;
            }),
        },
      };
      axios
        .request(config)
        .then((response) => {
          getTrainingState(accessToken);
          setAllDocData(response.data.Items);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setStartSubmitTrain(false));
    }
  };

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        {loaded ? (
          <DocList
            trainingDoc={trainingDoc}
            sendStartTrain={sendStartTrain}
            startSubmitTrain={startSubmitTrain}
            selectDoc={selectDoc}
          />
        ) : (
          <>
            <Skeleton />
          </>
        )}
      </div>
    </div>
  );
}
