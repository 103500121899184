import BotDescription from "./BotDescription";
import BotParams from "./BotParams";
import { useEffect, useState } from "react";
import { logicUrl } from "../../../../helper";
import axios from "axios";
import Skeleton from "../../../../components/Skeleton";
import LoadButton from "../../../../components/LoadButton";
import { useSnackbar } from "notistack";

export default function BotConfig({ accessToken }) {
  const { enqueueSnackbar } = useSnackbar();
  const [instructions, setInstructions] = useState();
  const [temperature, setTemperature] = useState(1);
  const [topP, setTopP] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [startSubmitParams, setStartSubmitParams] = useState(false);

  useEffect(() => {
    if (accessToken) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/botconfig/getUserBotConfig`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          const item = response?.data?.Items?.[0];
          setLoaded(true);
          setInstructions(item?.instructions?.S);
          setTemperature(item?.temperature?.N);
          setTopP(item?.topP?.N);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [accessToken]);

  const updateBotConfig = () => {
    setStartSubmitParams(true);
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${logicUrl}/botconfig/updateUserBotConfig`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        instructions: instructions,
        temperature: temperature,
        topP: topP,
      }),
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        enqueueSnackbar("Success", { variant: "success" });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setStartSubmitParams(false);
      });
  };

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        {loaded ? (
          <>
            <div>Parameter</div>
            <br />
            <BotDescription
              instructions={instructions}
              setInstructions={setInstructions}
            />
            <br />
            <BotParams
              temperature={temperature}
              setTemperature={setTemperature}
              topP={topP}
              setTopP={setTopP}
            />
            <div className="mt-5 sm:mt-6">
              <LoadButton
                onClick={updateBotConfig}
                content={"Save Parameter"}
                loading={startSubmitParams}
              />
            </div>
          </>
        ) : (
          <>
            <Skeleton />
          </>
        )}
      </div>
    </div>
  );
}
