import Train from "./Train";
import MyDoc from "./MyDoc";
import Setting from "./Setting";
import DefaultPage from "./DefaultPage";
import Web from "./Channel/Web";
import Sidebar from "../../components/Sidebar";
import { Routes, Route } from "react-router-dom";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useEffect, useState } from "react";

function Authed({ user, signOut }) {
  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    fetchAuthSession().then((session) => {
      setAccessToken(session.tokens.accessToken.toString());
    });
  }, []);
  return (
    <>
      <Routes>
        <Route path="/*" element={<DefaultPage />} />
        <Route
          path="/settings"
          element={
            <Sidebar
              user={user}
              signOutAction={signOut}
              component={<Setting accessToken={accessToken} />}
            />
          }
        />
        <Route
          path="/train"
          element={
            <Sidebar
              user={user}
              signOutAction={signOut}
              component={<Train accessToken={accessToken} />}
            />
          }
        />
        <Route
          path="/mydoc"
          element={
            <Sidebar
              user={user}
              signOutAction={signOut}
              component={<MyDoc accessToken={accessToken} />}
            />
          }
        />
        <Route
          path="/"
          element={
            <Sidebar
              user={user}
              signOutAction={signOut}
              component={<MyDoc accessToken={accessToken} />}
            />
          }
        />
        <Route
          path="/channel/web"
          element={
            <Sidebar
              user={user}
              signOutAction={signOut}
              component={<Web user={user} />}
            />
          }
        />
      </Routes>
    </>
  );
}

export default Authed;
