import Payment from "./Payment";
import Usage from "./Usage";
import Security from "./Security";
import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Setting({ accessToken }) {
  const [currentTab, setCurrentTab] = useState(0);
  const tabs = [
    {
      name: "My subscription",
      component: <Payment accessToken={accessToken} />,
    },
    {
      name: "Usage",
      component: (
        <Usage accessToken={accessToken} setCurrentTab={setCurrentTab} />
      ),
    },
    {
      name: "Security",
      component: <Security accessToken={accessToken} />,
    },
  ];

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav aria-label="Tabs" className="flex space-x-4">
          {tabs.map((tab, index) => (
            <div
              key={tab.name}
              onClick={() => {
                setCurrentTab(index);
              }}
              aria-current={index === currentTab ? "page" : undefined}
              className={classNames(
                index === currentTab
                  ? "bg-gray-200 text-indigo-700"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
              )}
            >
              {tab.name}
            </div>
          ))}
        </nav>
      </div>
      {tabs[currentTab].component}
    </div>
  );
}
