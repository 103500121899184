"use client";

import { useEffect, useState } from "react";
import { Description, Field, Label, Switch } from "@headlessui/react";
import LoadButton from "../../../../components/LoadButton";
import { logicUrl } from "../../../../helper";
import axios from "axios";
import Skeleton from "../../../../components/Skeleton";
import { useSnackbar } from "notistack";

export default function Security({ accessToken }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isEnable, setIsEnable] = useState(false);
  const [ipList, setIpList] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [startSubmitSecurity, setStartSubmitSecurity] = useState(false);

  useEffect(() => {
    getAllowIpSetting(accessToken);
  }, [accessToken]);

  const getAllowIpSetting = (token) => {
    if (token) {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/security/getAllAllowIp`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setIsEnable(
            response.data?.allAllowIp?.Items?.[0]?.isEnable?.BOOL || false
          );
          setIpList(response.data?.allAllowIp?.Items?.[0]?.ipList?.S || "");
          setLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const saveAllowIpSetting = async () => {
    try {
      setStartSubmitSecurity(true);
      const ipListFormated = ipList.split(",");
      const totalIp = ipListFormated.length;
      if (totalIp > 0) {
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${logicUrl}/security/addAllowIp`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          data: JSON.stringify({
            isEnable,
            ipList,
          }),
        };

        axios
          .request(config)
          .then((response) => {
            enqueueSnackbar("Success", { variant: "success" });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Fail to update ip list, please check you ip format");
    } finally {
      setStartSubmitSecurity(false);
    }
  };

  return (
    <>
      <br />
      <br />
      {loaded ? (
        <>
          <Field className="flex items-center justify-between">
            <span className="flex flex-grow flex-col">
              <Label
                as="span"
                passive
                className="text-sm font-medium leading-6 text-gray-900"
              >
                Disable To Public
              </Label>
              <Description as="span" className="text-sm text-gray-500">
                You can set a ip address list to let the bot only be available
                in your private space
              </Description>
            </span>
            <Switch
              checked={isEnable}
              onChange={setIsEnable}
              className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
            >
              <span
                aria-hidden="true"
                className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
              />
            </Switch>
          </Field>
          <br />
          <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
            <label htmlFor="description" className="sr-only">
              Description
            </label>
            <textarea
              disabled={!isEnable}
              id="description"
              name="description"
              rows={18}
              placeholder={`210.253.222.211,
223.255.233.222`}
              className="disabled:bg-gray-300 block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              value={ipList}
              onChange={(e) => {
                setIpList(e.target.value);
              }}
            />
          </div>
          <br />

          <LoadButton
            content={"SAVE"}
            onClick={saveAllowIpSetting}
            loading={startSubmitSecurity}
          />
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
}
