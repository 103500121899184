import {
  Dropzone,
  FileMosaic,
  FullScreen,
  ImagePreview,
  VideoPreview,
} from "@files-ui/react";
import { useEffect, useState } from "react";
import { logicUrl } from "../../../../../helper";
import axios from "axios";
import { useSnackbar } from "notistack";

export default function UploadDocBar({ accessToken, get }) {
  const { enqueueSnackbar } = useSnackbar();
  const [extFiles, setExtFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);
  const [videoSrc, setVideoSrc] = useState(undefined);
  const [updateEffect, setUpdateEffect] = useState();

  const updateFiles = (incommingFiles) => {
    console.log("incomming files", incommingFiles);
    setExtFiles(incommingFiles);
  };
  const onDelete = (id) => {
    setExtFiles(extFiles.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };
  const handleWatch = (videoSource) => {
    setVideoSrc(videoSource);
  };
  const handleStart = (filesToUpload) => {
    console.log("advanced demo start upload", filesToUpload);
  };
  const handleFinish = (uploadedFiles) => {
    console.log("advanced demo finish upload", uploadedFiles);
    setExtFiles([]);
  };
  const handleAbort = (id) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: "aborted" };
        } else return { ...ef };
      })
    );
  };
  const handleCancel = (id) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined };
        } else return { ...ef };
      })
    );
  };

  useEffect(() => {
    // console.log("extFiles", extFiles);
  }, [extFiles]);

  useEffect(() => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === updateEffect.id) {
          return {
            ...ef,
            uploadStatus: updateEffect.uploadStatus,
            progress: updateEffect.progress,
          };
        } else return { ...ef };
      })
    );
  }, [updateEffect]);

  const startUpload = () => {
    const uploadToS3 = (url, file, id) => {
      return new Promise((resolve, reject) => {
        let config = {
          method: "put",
          maxBodyLength: Infinity,
          url: url,
          data: file,
          onUploadProgress: (data) => {
            console.log("id data", id, data.loaded);
            setUpdateEffect({
              id,
              uploadStatus: "uploading",
              progress: Math.round((100 * data.loaded) / data.total),
            });
          },
        };

        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            // setExtFiles(extFiles.filter((extFile) => extFile.id !== id));
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    };
    const action = extFiles.map((extFile) => {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/doc/userDocPerSignedUrl`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: JSON.stringify({
          filename: extFile.name,
        }),
      };

      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            uploadToS3(response.data, extFile.file, extFile.id).then(
              (s3result) => {
                resolve(s3result);
              }
            );
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    });

    Promise.all(action).then((values) => {
      console.log(values);
      setExtFiles(
        extFiles.map((ef) => {
          return {
            ...ef,
            uploadStatus: "success",
          };
        })
      );
      enqueueSnackbar("Success", { variant: "success" });
      function delay(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }
      delay(2000).then(() => get(accessToken));
    });
  };

  return (
    <>
      <Dropzone
        onChange={updateFiles}
        minHeight="195px"
        value={extFiles}
        accept="application/*"
        maxFiles={10}
        maxFileSize={50 * 1024 * 1024}
        label="Drag'n drop files here or click to browse"
        // uploadConfig={{
        //   // autoUpload: true
        //   url: BASE_URL,
        //   cleanOnUpload: true,
        //   method: "PUT",
        // }}
        onUploadStart={handleStart}
        onUploadFinish={handleFinish}
        fakeUpload={true}
        // actionButtons={{
        //   position: "after",
        //   // abortButton: {},
        //   // deleteButton: {},
        //   // uploadButton: {},
        // }}
      >
        {extFiles.map((file) => (
          <FileMosaic
            {...file}
            key={file.id}
            onDelete={onDelete}
            onSee={handleSee}
            onWatch={handleWatch}
            onAbort={handleAbort}
            onCancel={handleCancel}
            preview
          />
        ))}
      </Dropzone>
      <FullScreen
        open={imageSrc !== undefined}
        onClose={() => setImageSrc(undefined)}
      >
        <ImagePreview src={imageSrc} />
      </FullScreen>
      <FullScreen
        open={videoSrc !== undefined}
        onClose={() => setVideoSrc(undefined)}
      >
        <VideoPreview src={videoSrc} autoPlay controls />
      </FullScreen>
      <div className="mt-5 sm:mt-6">
        <button
          onClick={startUpload}
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Submit
        </button>
      </div>
    </>
  );
}
