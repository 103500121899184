import { logicUrl } from "../../../../helper";
import axios from "axios";
import { useEffect, useState } from "react";
import Skeleton from "../../../../components/Skeleton";

export default function Usage({ accessToken, setCurrentTab }) {
  const [usageItems, setUsageItems] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (accessToken) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/usage/getUsage`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          const res = response?.data.userUsage.Items[0];
          setUsageItems(res);
          setLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [accessToken]);

  if (loaded) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto"></div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead></thead>
                <tbody className="bg-white">
                  <tr className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {"Period End"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {usageItems?.endTime?.N ? (
                        new Date(parseInt(usageItems?.endTime?.N * 1000))
                          .toLocaleDateString("zh", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                          .replaceAll("/", "-")
                      ) : (
                        <>
                          <div class="inline-flex items-baseline">
                            <p>
                              You don't have any sunscription, please &nbsp;
                            </p>
                            <p
                              href="#"
                              className="cursor-pointer underline"
                              onClick={() => {
                                setCurrentTab(0);
                              }}
                            >
                              create a new one
                            </p>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {"Max File To Train"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {usageItems?.fileLimit?.N || 0}
                    </td>
                  </tr>
                  <tr className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {"Max Message To Send"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {usageItems?.messageLimit?.N || 0}
                    </td>
                  </tr>
                  <tr className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {"Current Uploaded File"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {usageItems?.fileUsage?.N || 0}
                    </td>
                  </tr>
                  <tr className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {"Current Send Message"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {usageItems?.messageUsage?.N || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Skeleton />;
  }
}
