import { useEffect } from "react";
// import CodeEditor from "@uiw/react-textarea-code-editor";
import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";

export default function Web({ user }) {
  const botHtml = `
<!DOCTYPE html>
<html>
  <head>
    <script type="module" crossorigin src="https://sbotwidget.thesimplelanding.com/assets/bot.js"></script>
    <link rel="stylesheet" crossorigin href="https://sbotwidget.thesimplelanding.com/assets/bot.css">
  </head>
  <body>
    <div
      id="bot"
      fullScreenMode="true"
      botid="${user?.username}"
      welcomeMessage="Hello there! I'm your friendly chatbot, here to assist you with anything you need. Whether you have questions, need support, or just want to chat, I'm here for you 24/7. Feel free to ask me anything or let me know how I can help you today!"
      title="ChatBot"
      launcherText="help"
      inputPlaceholder="Type message here"
    ></div>
  </body>
</html>

  `;

  useEffect(() => {
    const iframe = document.createElement("iframe");
    // iframe.src = "data:text/html;charset=utf-8," + encodeURI(botHtml);
    if (!document.getElementById("iframeContainer").hasChildNodes()) {
      document.getElementById("iframeContainer").appendChild(iframe);
      iframe.style.height = "80vh";
      iframe.style.width = "100%";
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(botHtml);
      iframe.contentWindow.document.close();
    }
  }, []);

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-12 px-6 sm:gap-y-16 lg:grid-cols-2 lg:px-8">
        <article className="mx-auto w-full max-w-2xl lg:mx-0 lg:max-w-lg">

          <CodeMirror
            value={botHtml}
            height="500px"
            extensions={[html()]}
            editable={false}
            readOnly={true}
          />
        </article>
        <div className="mx-auto w-full max-w-2xl border-t border-gray-900/10 pt-12 sm:pt-16 lg:mx-0 lg:max-w-none lg:border-t-0 lg:pt-0">
          <div className="-my-12 divide-y divide-gray-900/10">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <div className="px-4 py-5 sm:p-6">
                {<div id={"iframeContainer"}></div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
