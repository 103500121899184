import DisplayDoc from "./DisplayDoc";
import DisplayTrainState from "./DisplayTrainState";
import { logicUrl } from "../../../../helper";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

export default function SelectDocArea({ accessToken }) {
  const { enqueueSnackbar } = useSnackbar();
  const [trainingState, setTrainingState] = useState();
  const [isTraining, setIsTraining] = useState();

  useEffect(() => {
    getTrainingState(accessToken);
  }, [accessToken]);

  useEffect(() => {
    setIsTraining(trainingState?.userVs?.file_counts?.in_progress > 0);
  }, [trainingState]);

  useEffect(() => {
    if (isTraining) {
      const interval = setInterval(() => {
        getTrainingState(accessToken);
      }, 20000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTraining]);

  const getTrainingState = (accessToken) => {
    if (accessToken) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/bot/retrieveTraining`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data?.userVs?.file_counts?.in_progress > 0) {
            enqueueSnackbar("Your bot is still training");
          } else {
            enqueueSnackbar("All Train Finish, Your Bot is ready", {
              variant: "success",
            });
          }
          setTrainingState(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  if (isTraining) {
    return <DisplayTrainState trainingState={trainingState} />;
  } else {
    return (
      <DisplayDoc
        trainingState={trainingState}
        accessToken={accessToken}
        getTrainingState={getTrainingState}
      />
    );
  }
}
