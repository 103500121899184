import { useEffect, useState } from "react";
import UploadDoc from "./UploadDoc";
import { logicUrl, humanFileSize } from "../../../helper";
import { useSnackbar } from "notistack";
import axios from "axios";
import LoadButton from "../../../components/LoadButton";
import Skeleton from "../../../components/Skeleton";
import DesPopover from "../../../components/DesPopover";

export default function MyDoc({ accessToken }) {
  const { enqueueSnackbar } = useSnackbar();
  const [allDocData, setAllDocData] = useState();
  const [deleteDoc, setDeleteDoc] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    get(accessToken);
  }, [accessToken]);

  const get = (tokrn) => {
    if (tokrn) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/doc/getAllFile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokrn}`,
        },
        data: "data",
      };
      axios
        .request(config)
        .then((response) => {
          setAllDocData(response.data.Items);
          setLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const del = (fileId) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${logicUrl}/doc/delUserDoc`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: { fileId: fileId?.S },
    };
    axios
      .request(config)
      .then((response) => {
        get(accessToken);
        enqueueSnackbar("Success", { variant: "success" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Document
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Upload your document and goto training page to have a chatbot with
            your own data
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <UploadDoc accessToken={accessToken} get={get} />
        </div>
      </div>
      {loaded ? (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      File Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Size
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Create Time
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      State
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {allDocData &&
                    allDocData.map((docData) => (
                      <tr key={docData.fileId?.S}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {docData.fileName?.S}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {humanFileSize(docData.size?.N)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {new Date(parseInt(docData.createTime?.N))
                            .toLocaleDateString("zh", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            })
                            .replaceAll("/", "-")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {docData.state?.S === "SUCCESS" ? (
                            "SUCCESS"
                          ) : (
                            <DesPopover
                              title={"FAIL 💡"}
                              description={
                                "Please check your plan limits or contact us"
                              }
                              href="/settings"
                            />
                          )}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <LoadButton
                            content={"Delete"}
                            onClick={() => {
                              setDeleteDoc([...deleteDoc, docData.fileId?.S]);
                              del(docData?.fileId);
                            }}
                            loading={deleteDoc.includes(docData.fileId?.S)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Skeleton />
        </>
      )}
    </div>
  );
}
