"use client";

import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { humanFileSize } from "../../../../../../helper";
import LoadButton from "../../../../../../components/LoadButton";

export default function DisplayDoc({
  trainingDoc,
  sendStartTrain,
  startSubmitTrain,
  selectDoc,
}) {
  return (
    <>
      {trainingDoc && trainingDoc.length > 0 ? (
        <>
          <fieldset>
            <legend className="text-sm font-semibold leading-6 text-gray-900">
              Select a document to train the bot
            </legend>
            <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
              {trainingDoc &&
                trainingDoc.map((docList) => (
                  <div
                    key={docList.fileId?.S}
                    onClick={() => {
                      selectDoc(docList.fileId?.S);
                    }}
                    className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600"
                  >
                    <span className="flex flex-1">
                      <span className="flex flex-col">
                        <span className="block text-sm font-medium text-gray-900">
                          {humanFileSize(docList.size?.N)}
                        </span>
                        <span className="mt-1 flex items-center text-sm text-gray-500">
                          {docList.fileName?.S}
                        </span>
                        <span className="mt-6 text-sm font-medium text-gray-900">
                          {docList.localTime}
                        </span>
                      </span>
                    </span>
                    {docList?.select && (
                      <>
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-indigo-600"
                        />
                        <span
                          aria-hidden="true"
                          className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent [.group_&]:border-indigo-600"
                        />
                      </>
                    )}
                  </div>
                ))}
            </div>
          </fieldset>
          <br />
          <LoadButton
            onClick={sendStartTrain}
            content={"Start Train"}
            loading={startSubmitTrain}
          />
        </>
      ) : (
        <a
          className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          href="/mydoc"
        >
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 48 48"
            aria-hidden="true"
            className="mx-auto h-12 w-12 text-gray-400"
          >
            <path
              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="mt-2 block text-sm font-semibold text-gray-900">
            Add new Document
          </span>
        </a>
      )}
    </>
  );
}
