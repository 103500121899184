import CBDialogs from "./CBDialogs";
import { useState } from "react";

export default function ConfirmButton({
  content,
  onClick,
  title,
  description,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        onClick={() => {
          setOpen(true);
        }}
        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {content}
      </button>
      <CBDialogs
        onClick={onClick}
        open={open}
        setOpen={setOpen}
        title={title}
        description={description}
      />
    </>
  );
}
