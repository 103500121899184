import * as React from "react";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import DesPopover from "../../../../../components/DesPopover";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};

export default function BotParams({
  temperature,
  topP,
  setTemperature,
  setTopP,
}) {
  return (
    <Box>
      <div>
        <DesPopover
          title={"Temperature 💡"}
          description={
            "Control randomness. Lowering the temperature means the model produces a more repeatable and deterministic response. Increasing the temperature will result in more unexpected or creative responses. Try adjusting either the temperature or the Top P, but not both."
          }
        />
      </div>
      <DesPopover />
      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        value={parseFloat(temperature)}
        onChange={(e, val) => {
          setTemperature(val);
        }}
        max={1}
        min={0.01}
        step={0.01}
      />
      <div>
        <DesPopover
          title={"Top P 💡"}
          description={
            "Like temperature, this option controls randomness, but uses a different method. Lowering Top P narrows the model's selection of wands to the wands it prefers. Increasing Top P allows the model to choose from high and low probability maces. Try adjusting either the temperature or the Top P, but not both."
          }
        />
      </div>
      <DesPopover />{" "}
      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        value={parseFloat(topP)}
        onChange={(e, val) => {
          setTopP(val);
        }}
        max={1}
        min={0.01}
        step={0.01}
      />
    </Box>
  );
}
