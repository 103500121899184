import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import Authed from "./Page/Authed";
import { SnackbarProvider } from "notistack";
import { userPoolClientId, userPoolId, region } from "./helper";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: userPoolClientId,
      userPoolId: userPoolId,
      region: region,
    },
  },
});
function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Authenticator loginMechanisms={["email"]}>
        {({ signOut, user }) => (
          <main>
            <Authed signOut={signOut} user={user} />
          </main>
        )}
      </Authenticator>
    </SnackbarProvider>
  );
}

export default App;
