import Plan from "./Plan";
import SubscriptionState from "./SubscriptionState";
import { useEffect, useState } from "react";
import { logicUrl } from "../../../../helper";
import axios from "axios";
import Skeleton from "../../../../components/Skeleton";

function Payment({ accessToken }) {
  const [subscription, setSubscription] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (accessToken) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${logicUrl}/payment/checkSubscription`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          const res = response?.data?.subscriptionState;
          setSubscription(res);
          setLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [accessToken]);

  if (loaded) {
    if (subscription?.status === "active") {
      return (
        <SubscriptionState
          subscription={subscription}
          accessToken={accessToken}
          
        />
      );
    } else {
      return <Plan accessToken={accessToken} />;
    }
  } else {
    return <Skeleton />;
  }
}

export default Payment;
